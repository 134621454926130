<template>
  <div class="sas-wrapper">
    <div class="cards-container">
      <EssayCard
        v-for="essay in essays"
        :key="essay"
        :file-path="essay"
      />
    </div>
    <div
      v-if="emptyInstructions"
      class="empty-instructions"
    >
      <p>Parece que não há instruções por enquanto...</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import EssayCard from '@/components/EssayCard'

export default {
  name: 'ExamResultEssay',

  components: {
    EssayCard,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => vm.$emit('select-tab', vm.$route.name))
  },

  data() {
    return {
      essays: [],
    }
  },

  computed: {
    ...mapGetters(['assessment']),

    emptyInstructions() {
      return this.essays.length === 0
    },
  },

  created() {
    this.load()
  },

  methods: {
    load() {
      const { essayInstructionsStudent } = this.assessment

      if (essayInstructionsStudent) {
        this.essays = [essayInstructionsStudent]
      }
    },
  },
}
</script>

<style lang="sass">

  .sas-wrapper
    max-width: 1032px
    margin: 0 auto

  .cards-container
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 12px 18px
    margin-top: 24px

  .empty-instructions
    width: 100%
    margin-top: $size-l
    text-align: center

</style>

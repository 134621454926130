<template>
  <div
    class="card"
  >
    <div class="card__icon">
      <img
        alt="Start exam illustration"
        class="exam-modal__header_image"
        src="@/assets/essay-card-icon.svg"
      >
    </div>

    <div class="card__title">
      <span> {{ title || 'Critérios Redação nota 1.000' }} </span>
    </div>

    <div class="card__button">
      <SasButton class="download-button">
        <a
          class="redirect"
          :href="filePath"
          rel="noopener noreferrer"
          target="_blank"
        >
          Baixar
        </a>
      </SasButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EssayCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    filePath: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass">

.card
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  background: white
  border-radius: $border_radius_l
  border-top: 1px solid $color_ink_lightest
  width: 220px
  height: 216px
  padding: 0
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2)

  &__icon
    padding-left: $size-s
    width: $size-xl
    height: $size-xl

  &__title
    margin: $size-s 0
    padding: 0 $size-s
    color: $color-ink
    font-weight: $font_weight_medium
    height: 50px

  &__button
    border-top: 1px solid $color_ink_lightest
    padding: $size-xs 0 0 $size-s
    width: 100%

.download-button
  margin-top: $size-xs
  width: 60px
  height: 28px
  font-size: $font_size_xs

</style>
